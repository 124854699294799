import { Injectable } from '@angular/core';
import { environment as environmentProd } from "src/assets/environment.prod";
import { environment as environmentLocal } from "src/assets/environment";

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

    private environment: any;

    constructor() {
      // Détecte automatiquement l'environnement (par exemple via `production` flag)
      if (environmentProd.production) {
        this.environment = environmentProd;
      } else {
        this.environment = environmentLocal;
      }
    }
  
    // Méthodes pour récupérer les variables dynamiques
    get baseUrlBackend(): string {
      return this.environment.baseUrlBackend;
    }
  
    get baseUrlBackendPrincipal(): string {
      return this.environment.baseUrlBackendPrincipal;
    }
  
    get webUrl(): string {
      return this.environment.webUrl;
    }
}
