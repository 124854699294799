import { Menu } from './menu.model';

export const verticalMenuItems = [

  new Menu(1, 'Tableau de Bord', '/', null, 'dashboard', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_REGISTRE"]),
  new Menu(2, 'Réunion', '/meet', null, 'perm_contact_calendar', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  new Menu(3, 'Registre', '/registre', null, 'maps', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", , "ROLE_REGISTRE"]),
  
  new Menu(4, 'Comptabilité', '/', null, 'multiline_chart', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT", "ROLE_USER"]),
  new Menu(37, 'Réunion', '/comptabilite/liste', null, 'multiline_chart', null, false, 4, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT", "ROLE_USER"]),
  new Menu(38, 'Type Paiement', '/comptabilite/typePerdium', null, 'multiline_chart', null, false, 4, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),
  
  new Menu(7, 'Agenda', '/agenda', null, 'calendar_month', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_PRODUCTEUR"]),
  new Menu(5, 'Utilisateurs', '/users', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(6, 'Utilisateurs mobile', '/user-mobile', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  //new Menu(36, 'Paramétrage', '/', null, 'settings', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PARAMETRAGE"]),
  
 
  
  //new Menu(49, 'Paramétre', '/parametrage-mobile', null, 'report', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_PARAMETRAGE"]),
  
]

export const horizontalMenuItems = [
  new Menu(1, 'Tableau de Bord', '/', null, 'dashboard', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_REGISTRE"]),
  new Menu(2, 'Réunion', '/meet', null, 'chrome_reader_mode', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER", "ROLE_REGISTRE"]),
  new Menu(3, 'Registre', '/registre', null, 'maps', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_USER"]),
  
  new Menu(4, 'Comptabilité', '/', null, 'multiline_chart', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT", "ROLE_USER"]),
  new Menu(37, 'Réunion', '/comptabilite/liste', null, 'multiline_chart', null, false, 4, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT", "ROLE_USER"]),
  
  new Menu(5, 'Utilisateurs', '/users', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(6, 'Utilisateurs mobile', '/user-mobile', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(38, 'Type Paiement', '/comptabilite/typePerdium', null, 'multiline_chart', null, false, 36, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),


]

export const favoriteMenuItems = [
  new Menu(1, 'Tableau de Bord', '/', null, 'dashboard', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_REGISTRE"]),
  new Menu(2, 'Réunion', '/meet', null, 'chrome_reader_mode', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "ROLE_PRODUCTEUR", "ROLE_USER", "ROLE_REGISTRE"]),
  new Menu(3, 'Registre', '/registre', null, 'maps', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  
  new Menu(4, 'Comptabilité', '/', null, 'multiline_chart', null, true, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT", "ROLE_USER"]),
  new Menu(37, 'Réunion', '/comptabilite/liste', null, 'multiline_chart', null, false, 4, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT", "ROLE_USER"]),
  new Menu(38, 'Type Paiement', '/comptabilite/typePerdium', null, 'multiline_chart', null, false, 35, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN", "STAT"]),
  
  new Menu(5, 'Utilisateurs', '/users', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),
  new Menu(6, 'Utilisateurs mobile', '/user-mobile', null, 'supervisor_account', null, false, 0, true, ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"]),

]