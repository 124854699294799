import { ConfigService } from "./config.service";

export class BackendServerConf {
  public static baseUrlBackend: string;
  public static baseUrlBackendPrincipal: string;
  public static webUrl: string;

  // Méthode statique pour initialiser les valeurs
  static initialize(service: ConfigService): void {
    this.baseUrlBackend = service.baseUrlBackend;
    this.baseUrlBackendPrincipal = service.baseUrlBackendPrincipal;
    this.webUrl = service.webUrl;
  }
}
